body {
  color: white;
  background-color: black;
}

a {
  text-decoration: underline !important;
}
/* 
::selection {
  background: white;
}
::-moz-selection {
  background: white; 
} */

/* .video-wrapper {
  padding: 10px;
  background: white;
  box-sizing: border-box;
  resize: horizontal;
  border: 1px dashed;
  overflow: auto;
  max-width: 100%;
  height: calc(100vh - 16px);
}

.video-container {
  width: 100%;
  padding-bottom: 75%;
  background: gold; 
} */

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* 
.selectEnable { 
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
} */

.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.video-1 {
  width: 100%;
}

.blur {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-blend-mode: multiply;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.blur.green {
  top: 30px;
  left: calc(50% - 432px);
  width: 588px;
  height: 588px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(3, 252, 7, 0.33),
    rgba(244, 206, 148, 0)
  );
}

.blur.purple {
  top: -164px;
  left: calc(50% - 516px);
  width: 526px;
  height: 526px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(144, 3, 252, 0.33) 10%,
    rgba(220, 193, 228, 0) 100%
  );
}

.blur.blue {
  top: -360px;
  left: calc(50% - 258px);
  width: 800px;
  height: 800px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 157, 255, 0.33) 10%,
    rgba(220, 193, 228, 0) 100%
  );
}

* {
  box-sizing: border-box;
}

/* * {
  box-sizing: border-box;
}

body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
} */

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container:after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: black;
  border: 3px solid #41ff00;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.container:hover:after {
  background-color: #41ff00;
  transition: all 300ms;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* .left::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}


.right::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
} */

/* Fix the circle for containers on the right side */
.right::after {
  left: -14px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  /* border: 2px solid white; */
  position: relative;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 800px) {
  .content {
    padding: 16px 0px;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 29px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 10px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

table td,
table td * {
  vertical-align: top;
}
